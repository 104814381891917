import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Flex, Box } from "@rebass/grid";
import CI from "@trackcode/ci";
import { StandardButton } from "@trackcode/ui";
import Layout from "../components/Layout";
import AppBar from "../components/AppBar";

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        pagesYaml(page: { eq: "start" }) {
          page
          documentation {
            text
            path
          }
        }
      }
    `}
    render={({ pagesYaml: { documentation } }) => (
      <Layout>
        <Flex
          flexDirection="column"
          flex={1}
          css={{ background: CI.color.grayLight }}
        >
          <AppBar />
          <Flex flex={1} alignItems="center" justifyContent="center">
            <Box>
              {/* WORKAROUND: Use post request to enforce a full reload to use basic authentication. */}
              <form method="GET" action={documentation.path}>
                <StandardButton type="submit" appearance="primary">
                  {documentation.text}
                </StandardButton>
              </form>
            </Box>
          </Flex>
        </Flex>
      </Layout>
    )}
  />
);

export default IndexPage;
